<template>
  <hb-form label="Reporting Term" :active.sync="active" editable @cancel="handleCancelEvent" @save="handleSaveEvent">
    <template v-slot:display>
      <v-row>
        <v-col class="pr-0" cols="3">
          Term:
        </v-col>
        <v-col class="pl-0" cols="3">
          {{term}}
        </v-col>
      </v-row>
      <v-row v-if="fiscalMonth.value">
        <v-col class="pr-0" cols="3">
          Starting Month:
        </v-col>
        <v-col class="pl-0" cols="3">
          {{fiscalMonth.name}}
        </v-col>
      </v-row>
    </template>
    <template v-slot:edit>
        <hb-radio-group v-model="term" row>
          <hb-radio label="Calendar Year" value="Calendar Year"></hb-radio>
          <hb-radio label="Fiscal Year" value="Fiscal Year"></hb-radio>
        </hb-radio-group>
        <hb-select 
          v-if="isFiscalYear"
          v-model="fiscalMonth"
          :items="monthList"
          item-text="name"
          return-object
          placeholder="Select Starting Month"
          :error="(isFiscalMonthSelectedError)"
        >
        </hb-select>
    </template>
  </hb-form>
</template>

<script type="text/babel">
import moment from 'moment';
import CALENDAR from '@/constants/calendar.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import api from "../../../assets/api.js";


export default {
  name: "FinancialReporting",
  mixins: [notificationMixin],
  props: {
    fiscalYearMonth: {
      type: String,
      default: "" 
    }
  },
  data() {
    return {
      active: '',
      monthList: CALENDAR.MONTHS_LIST,
      fiscalMonth:{
        name: moment().month(parseInt(this.fiscalYearMonth) - 1).format('MMMM'),
        value: parseInt(this.fiscalYearMonth)
      },
      term: this.fiscalYearMonth ? "Fiscal Year" : "Calendar Year",
      isFiscalMonthSelectedError: false
    }
  },
  computed:{
    isFiscalYear() {
      return this.term === "Fiscal Year"
    }
  },
  methods: {
    handleCancelEvent() {
      this.fiscalMonth = this.fiscalMonth || {};
      this.fiscalMonth.name = moment().month(parseInt(this.fiscalYearMonth) - 1).format('MMMM');
      this.fiscalMonth.value = parseInt(this.fiscalYearMonth);
      this.isFiscalMonthSelectedError = false;
      
      if(this.fiscalYearMonth) 
        this.term = "Fiscal Year"
    },
    async handleSaveEvent() {
      if (this.isFiscalYear && (!this.fiscalMonth || !this.fiscalMonth.value)) {
        this.isFiscalMonthSelectedError = true;
        this.showMessageNotification({ description: `Please select the Starting Month for Fiscal Year selection` })
        return
      }
  
      let month = "";
      if(this.isFiscalYear && this.fiscalMonth && this.fiscalMonth.value){
        month = this.fiscalMonth.value.toString();
      }
      else {
        this.fiscalMonth = {
            name: "",
            value: ""
          }
      }

      this.$emit('updateFiscalYearMonth', month);
      try {
        let data = {settings: {fiscalYearMonth: month}}
        await api.post(this, api.SETTINGS + `billing`, data);
        this.showMessageNotification({type: 'success', description: `Fiscal Year setting saved successfully` })
      }
      catch(err) {
        this.showMessageNotification({ description: `Failed to save Fiscal Year setting` })
      }

      this.active = 'saved';
      this.isFiscalMonthSelectedError = false;
    }
  }
  
}

</script>